const routes = {
  '/': 'Home',
  '/activity': 'Activity',
  '/activity/activity-feed': 'All',
  '/activity/daily-wod': 'WOD',
  '/members': 'Members',
  '/members/manage': 'Manage',
  '/members/general': 'General',
  '/members/invite-requests': 'Invite/Requests',
  '/participation': 'Participation',
  '/participation/engagement': 'Engagement',
  '/participation/attendance': 'Attendance',
  '/participation/committed-club': 'Committed Club',
  '/participation/mia': 'MIA',
  '/performance': 'Performance',
  '/performance/pr-board': 'PR Board',
  '/performance/fitness-level': 'Fitness Level',
  '/performance/lifts': 'Lifts',
  '/performance/intervals': 'Intervals',
  '/body-comp': 'Body Composition',
  '/body-comp/dashboard': 'Dashboard',
  '/body-comp/weigh-ins': 'Weigh-ins',
  '/body-comp/targets': 'Targets',
  '/body-comp/Progress': 'Progress',
  '/body-comp/general': 'Body Composition',
  '/settings': 'Settings',
  '/settings/edit': 'Edit',
  '/settings/privacy': 'Privacy',
  '/settings/prefs': 'Prefs',
  '/settings/code': 'Member Code',
  '/extras': 'Extras',
  '/extras/feeds': 'Feeds',
  '/extras/wodscreen': 'WODScreen',
  '/extras/wordpress': 'WordPress',
  '/extras/site': 'Webwidgets',
  '/extras/wod-concierge': 'WOD Concierge',
  '/planning': 'Planning',
  '/planning/calendar': 'Gym Tracks',
  '/planning/member': 'Personal Tracks',
  '/welcome': 'Welcome',
  '/mind-body-online': 'MINDBODY',
  '/mind-body-online/setup': 'Setup',
  '/mind-body-online/manage-mbo-members': 'Synced Members',
  '/mind-body-online/unlinked-mbo-members': 'Unlinked MINDBODY Members',
  '/mind-body-online/unlinked-btwb-members': 'Unlinked BTWB Members',
  '/tutorial': 'Tutorial'
};
export default routes;

export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      badge: {
        variant: 'info'
      }
    },
    {
      title: true,
      name: 'Members',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Activity',
      url: '/activity',
      icon: 'icon-bubbles',children: [
        {
          name: 'All',
          url: '/activity/activity-feed',
          icon: 'icon-key'
        },
        {
          name: 'WOD',
          url: '/activity/daily-wod',
          icon: 'icon-eye'
        }
      ]
    },
    {
      name: 'Participation',
      url: '/participation',
      icon: 'icon-location-pin',
      children: [
        {
          name: 'Engagement',
          url: '/participation/engagement',
          icon: 'icon-emotsmile'
        },
        {
          name: 'Attendance',
          url: '/participation/attendance',
          icon: 'icon-user-following'
        },
        {
          name: 'Committed Club',
          icon: 'icon-settings',
          url: '/participation/committed-club'
        },
        {
          name: 'MIA',
          icon: 'icon-settings',
          url: '/participation/mia'
        }
      ]
    },
    {
      name: 'Performance',
      url: '/performance',
      icon: 'icon-badge',
      children: [
        {
          name: 'PR Board',
          url: '/performance/pr-board',
          icon: 'icon-user-following'
        },
        {
          name: 'Fitness Level',
          url: '/performance/fitness-level',
          icon: 'icon-settings'
        },
        {
          name: 'Lifts',
          url: '/performance/lifts',
          icon: 'icon-settings'
        },
        {
          name: 'Intervals',
          url: '/performance/intervals',
          icon: 'icon-settings'
        }
      ]
    },
    {
      name: 'Body Comp',
      url: '/body-comp',
      icon: 'icon-direction',
      children: [
        {
          name: 'Dashboard',
          url: '/body-comp/dashboard',
          icon: 'icon-notebook'
        },
        {
          name: 'Weigh-ins',
          url: '/body-comp/weigh-ins',
          icon: 'icon-pie-chart'
        },
        {
          name: 'Targets',
          url: '/body-comp/targets',
          icon: 'icon-location-pin'
        },
        {
          name: 'Progress',
          url: '/body-comp/progress',
          icon: 'icon-speedometer'
        }/*,
        {
          name: 'General',
          url: '/body-comp/general',
          icon: 'icon-user-following'
        }*/
      ]
    },
    /*{
      name: 'Macros',
      url: '/macros',
      icon: 'icon-basket-loaded',
      children: [
        {
          name: 'Dashboard',
          url: '/macros/dashboard',
          icon: 'icon-notebook'
        }
      ]
    },*/
    {
      divider: true
    },
    {
      title: true,
      name: 'Admin',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Membership',
      url: '/members',
      icon: 'icon-user',
      children: [
        /*{
          name: 'General',
          url: '/members/general',
          icon: 'icon-user-following'
        },*/
        {
          name: 'Manage',
          url: '/members/manage',
          icon: 'icon-settings'
        },
        {
          name: 'Add',
          url: '/members/invite-requests',
          icon: 'icon-paper-plane',
          hideOn: 'CoachingTeam'
        },
        {
          divider: true
        },
        {
          name: 'Create Member',
          url: 'https://btwb.com',
          label: 'console_create_member',
          createMember: true,
          icon: 'icon-user',
          hideOn: 'CoachingTeam'
        }
      ]
    },
    {
      name: 'Planning',
      url: '/planning',
      icon: 'icon-pencil',
      children: [
        {
          name: 'Gym Tracks',
          url: '/planning/calendar',
          icon: 'icon-shield'
        },/*
        {
          name: 'Programming Analysis',
          url: '/planning/member',
          icon: 'icon-shield'
        },*/
        {
          name: 'Personal Tracks',
          url: '/planning/member',
          icon: 'icon-shield'
        }
      ]
    },
    {
      name: 'Settings',
      url: '/settings',
      icon: 'icon-rocket',
      children: [
        {
          name: 'Member Code',
          url: '/settings/code',
          icon: 'icon-cursor',
          hideOn: 'CoachingTeam'
        },
        {
          name: 'Edit',
          url: '/settings/edit',
          icon: 'icon-cursor'
        },
        {
          name: 'Privacy',
          url: '/settings/privacy',
          icon: 'icon-cursor',
          hideOn: 'CoachingTeam'
        },
        {
          name: 'Prefs',
          url: '/settings/prefs',
          icon: 'icon-cursor',
          hideOn: 'CoachingTeam'
        },
        {
          name: 'Subscription',
          subscription: true,
          url: 'https://beyondthewhiteboard.com/subscriptions/gyms',
          icon: 'icon-cursor',
          label: 'console_sub',
          hideOn: 'CoachingTeam'
        }
      ]
    },
    {
      name: 'Extras',
      url: '/extra',
      icon: 'icon-star',
      children: [
        {
          name: 'WODScreen',
          url: '/extras/wodscreen',
          icon: 'icon-paper-plane'
        },
        {
          name: 'Wordpress',
          url: '/extras/wordpress',
          icon: 'icon-paper-plane'
        },
        {
          name: 'Webwidgets',
          url: '/extras/site',
          icon: 'icon-paper-plane'
        },
        {
          name: 'WOD Concierge',
          url: '/extras/wod-concierge',
          icon: 'icon-paper-plane'
        }
      ]
    },

    {
      divider: true
    },
    {
      title: true,
      name: 'Integrations',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'MINDBODY',
      url: '/mind-body-online',
      icon: 'icon-user',
      hidden: true,
      children: [
        {
          name: 'Setup',
          url: '/mind-body-online/setup',
          icon: 'icon-paper-plane'
        },
        {
          name: 'Synced Members',
          url: '/mind-body-online/manage-mbo-members',
          icon: 'icon-paper-plane'
        },
        {
          name: 'Unlinked BTWB',
          url: '/mind-body-online/unlinked-btwb-members',
          icon: 'icon-paper-plane'
        },
        {
          name: 'Unlinked MBO',
          url: '/mind-body-online/unlinked-mbo-members',
          icon: 'icon-paper-plane'
        }
      ]
    },
    /*{
      name: 'Pike13',
      url: '/pike13',
      icon: 'icon-user',
      hidden: true,
      children: [
        {
          name: 'Setup',
          url: '/pike13/setup',
          icon: 'icon-paper-plane'
        },
        {
          name: 'Synced Members',
          url: '/pike13/manage',
          icon: 'icon-paper-plane'
        },
        {
          name: 'Unlinked BTWB',
          url: '/pike13/unlinked-btwb',
          icon: 'icon-paper-plane'
        },
        {
          name: 'Unlinked Pike13',
          url: '/pike13/unlinked-pike13',
          icon: 'icon-paper-plane'
        }
      ]
    },*/
    {
      divider: true
    },
    {
      name: 'FAQ',
      url: 'http://support.btwb.com/en/support/solutions/35000137515',
      label: 'faq',
      icon: 'icon-paper-plane',
    },
    {
      name: 'Coaches Corner',
      url: '/coaches-corner',
      icon: 'icon-bulb',
      /*badge: {
        variant: 'info',
        text: 'NEW'
      }*/
    },
    {
      name: 'Fitness Communities',
      url: '/fitness-communities',
      icon: 'icon-plus',
    },
  ]
};

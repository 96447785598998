import React from 'react';
import {Route, Link} from 'react-router-dom';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import routes from '../../routes';
import Tooltip from 'react-tooltip';

const findRouteName = url => routes[url];

const getPaths = (pathname) => {
  const paths = ['/'];

  if (pathname === '/') return paths;

  pathname.split('/').reduce((prev, curr, index) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

const BreadcrumbsItem = ({match, ...rest}) => {
  const routeName = findRouteName(match.url);
  // Sir haxalot
  if(match.path.split('/').length === 2 && match.path.split('/')[1] !== '') match.isExact = true;
  if (routeName) {
    return (
      match.isExact ?
        (
          <BreadcrumbItem active>{routeName}</BreadcrumbItem>
        ) :
        (
          <BreadcrumbItem>
            <Link to={match.url || ''}>
              {routeName}
            </Link>
          </BreadcrumbItem>
        )
    );
  }
  return null;
};

const Breadcrumbs = ({location : {pathname}, match, ...rest}) => {
  const paths = getPaths(pathname);
  const items = paths.map((path, i) => <Route key={i++} path={path} component={BreadcrumbsItem}/>);
  return (
    <div>
      <Breadcrumb className="position-relative">
        {items}
        {/*<div className="quick-plan position-absolute right-0 pr-2 d-xs-none d-sm-none d-md-block">
          <a 
            className="px-2" 
            target="_blank" 
            href="https://beyondthewhiteboard.com/plan">
            <i className="icon icon-calendar"></i> Plan
          </a>
          <a 
            className="px-2" 
            target="_blank" 
            href="https://beyondthewhiteboard.com/subscriptions/gyms/">
            <i className="fa fa-dollar"></i> Subscription
          </a>
          <a 
            className="px-2" 
            target="_blank" 
            href="https://wodscreen.com">
            <i className="fa fa-tv"></i> WODscreen
          </a>
        </div>*/}
      </Breadcrumb>
    </div>
  );
};

export default props => (
  <div>
    <Route path="/:path" component={Breadcrumbs} {...props} />
  </div>
);

import {
  GET_MANAGE, 
  GET_PENDING, 
  REMOVE_MEMBER, 
  UPDATE_MEMBER_ROLE, 
  UPDATE_MEMBER_CODE, 
  GET_MEMBER_CODE,
  INVITE_MEMBER,
  RESET_MEMBERS,
  SENDING_CSV_FILE,
  GETTING_CSV_FILE
} from '../constants/constants';

const initState = {
  manage: [],
  pending: [],
  fetching: false,
  sendingInvite: false,
  sendingRemove: false,
  updatingRole: false,
  code: '',
  sendingCode: false,
  savingCsv: false,
  fetchedCsv: false,
  fetchingCsvFile: false,
  responseCsv: {errors: [], gym_imported_athletes: []}
};

const data = (state = initState, action) => {
  switch (action.type) {
    // rewrite the main functionality into single GET/SET calls to the API instead of separating out into GET/RECEIVE/SET/SET-RECEIVE etc
    case GET_MANAGE: 
      return Object.assign({}, state, {
        fetching: action.fetching,
        manage: action.manage ? action.manage : []
      });
    case GET_PENDING:
      return Object.assign({}, state, {
        pending: action.pending ? action.pending : []
      })

    // State changes for User Specific Actions
    case REMOVE_MEMBER:
      return Object.assign({}, state, {
        sendingRemove: action.sendingRemove
      });
    case UPDATE_MEMBER_ROLE: 
      return Object.assign({}, state, {
        updatingRole: action.updatingRole
      });
    case GET_MEMBER_CODE:
      return Object.assign({}, state, {
        code: action.code
      });
    case UPDATE_MEMBER_CODE:
      return Object.assign({}, state, {
        code: action.code ? action.code : state.code,
        sendingCode: action.sendingCode
      });
    case INVITE_MEMBER:
      return Object.assign({}, state, {
        sendingInvite: action.sendingInvite
      });
    case RESET_MEMBERS:
      return Object.assign({}, state, {
        manage: [],
        pending: [],
        fetching: false,
        sendingInvite: false,
        sendingRemove: false,
        updatingRole: false,
        code: '',
        sendingCode: false
      });
    case SENDING_CSV_FILE:
    console.log(action)
      return Object.assign({}, state, {
        savingCsv: action.savingCsv,
        responseCsv: action.responseCsv ? action.responseCsv : {errors: [], gym_imported_athletes: []}
      });
    case GETTING_CSV_FILE: 
      return Object.assign({}, state, {
        fetchedCsv: action.fetchedCsv,
        fetchingCsvFile: action.fetchingCsvFile,
      });

    default:
      return state
  }
}

export default data;

import React, {Component, Fragment, Suspense} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import Sidebar from '../../components/Sidebar/';
import Breadcrumb from '../../components/Breadcrumb/';
import AsyncComponent from '../AsyncComponent'
import { urlDev } from '../../constants/constants';
import moment from 'moment';

// custom Redux stuff
import {
  Container, 
  Alert, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Button
} from 'reactstrap';
import { 
    authCheck,
    switchScope,
    logout
} from '../../actions/auth';
import {
  getMemberDetail
} from '../../actions/member';
import { 
  error, 
  dashboardFetch,
  toggleEmoji,
  getComments
} from '../../actions/general';
import {
  getStatus
} from '../../actions/fitnessCommunities';
import {
  getExtras
} from '../../actions/extras';

const AsyncDashboard = AsyncComponent(() => import('../Dashboard'));
const AsyncMembers = AsyncComponent(() => import('../Members'));
const AsyncParticipation = AsyncComponent(() => import('../Participation'));
const AsyncMember = AsyncComponent(() => import('../Members/Member.js'));
const AsyncBodyComp = AsyncComponent(() => import('../BodyComp'));
const AsyncMacros = AsyncComponent(() => import('../Macros'));
const AsyncPerformance = AsyncComponent(() => import('../Performance'));
const AsyncSettings = AsyncComponent(() => import('../Settings'));
const AsyncExtras = AsyncComponent(() => import('../Extras'));
const AsyncPlanning = AsyncComponent(() => import('../Planning'));
const AsyncFeedback = AsyncComponent(() => import('../Feedback'));
const AsyncActivity = AsyncComponent(() => import('../Activity'));
const AsyncHeader = AsyncComponent(() => import('../../components/Header/'));
const AsyncWelcome = AsyncComponent(() => import('../Welcome'));
const AsyncMindBodyOnline = AsyncComponent(() => import('../MindBodyOnline'));
const AsyncPike13 = AsyncComponent(() => import('../Pike13'));
const AsyncTutorial = AsyncComponent(() => import('../Tutorial'));
const AsyncFaq = AsyncComponent(() => import('../Faq'));
const AsyncCoachesCorner = AsyncComponent(() => import('../CoachesCorner'));
const AsyncFitnessCommunities = AsyncComponent(() => import('../FitnessCommunities'));

ReactGA.initialize(ANALYTICS);
ReactGA.pageview(window.location.pathname + window.location.search);

class Full extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      errorModal: false
    }

    this.toggle = this.toggle.bind(this);
    this.errorToggle = this.errorToggle.bind(this);
    this.toggleOn = this.toggleOn.bind(this);
    this.errorToggleOn = this.errorToggleOn.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentDidMount() {
    return;
  }

  errorToggle() {
    this.props.error(false);
    this.setState({
      errorModal: !this.state.errorModal
    });
  }

  toggleOn() {
    return this.setState({
      modal: true
    });
  }

  errorToggleOn() {
    this.setState({
      errorModal: true
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextProps.location.pathname === '/dashboard' && this.props.location.pathname == '/') {
      this.props.authCheck();
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {

    let calendy = document.getElementsByClassName('calendly-badge-widget')[0];

    if(calendy) {
      calendy.removeEventListener('click', () => {});
      calendy.addEventListener('click', () => {
        return ReactGA.event({
          category: 'Walkthrough',
          action: 'click',
          label: 'open_calendy'
        });
      })
    }
    // if no token and our modal is not activated
    if((!this.props.auth.token && !this.props.auth.isFetching) && !this.state.modal) {
      this.toggleOn();
    }

    if(this.props.general.error && !this.state.errorModal) {
      this.errorToggleOn();
    }
  }

  render() {
    return (
      <div className="app">
        <AsyncHeader 
          history={this.props.history} 
          auth={this.props.auth} 
          switchScope={this.props.switchScope} 
          logout={this.props.logout}
        />
        <div className="app-body">
          <Sidebar {...this.props}/>
          <main className="main">
            <Breadcrumb/>
            <Container fluid>
              <Suspense fallback="loading">
                <Switch>
                  <Route path="/dashboard" name="Dashboard" render={
                    ()=> <AsyncDashboard {...this.props} />
                  }/>

                  <Route path="/members" name="Members" render={
                    ()=> <AsyncMembers location={this.props.location} history={this.props.history} />
                  } />

                  <Route path="/activity" name="Activity" render={
                    ()=> <AsyncActivity toggleEmoji={this.props.toggleEmoji} location={this.props.location} />
                  } />

                  <Route path="/participation" name="Participation" render={
                    ()=><AsyncParticipation location={this.props.location} />
                  } />

                  <Route path="/performance" name="Performance" render={
                    ()=> <AsyncPerformance location={this.props.location} />
                  } />

                  <Route path="/body-comp" name="Body Comp" render={
                    ()=> <AsyncBodyComp location={this.props.location} history={this.props.history} />
                  } />

                  <Route path="/macros" name ="Macros" render={
                    () => <AsyncMacros /*{... this.props}*/ />
                  } />
                  
                  <Route path="/settings" name="Settings" render={
                    ()=> <AsyncSettings location={this.props.location} />
                  } />

                  <Route path="/extras" name="Extras" render={
                    ()=> <AsyncExtras location={this.props.location} />
                  } />

                  <Route path="/planning" name="Planning" render={
                    ()=> <AsyncPlanning location={this.props.location} />
                  } />

                  <Route path="/feedback" name="Feedback" render={
                    ()=> <AsyncFeedback />
                  } />

                  <Route path="/member" name="Member" render={
                    ()=><AsyncMember {...this.props} />
                  } />

                  <Route path="/welcome" name="Welcome" render={
                    ()=><AsyncWelcome />
                  }/>

                  <Route path="/mind-body-online" name="Mind Body Online" render={
                    ()=><AsyncMindBodyOnline />
                  }/>

                  <Route path="/pike13" name="Pike13" render={
                    ()=><AsyncPike13 />
                  }/>

                  <Route path="/tutorial" name="Tutorial" render={
                    ()=><AsyncTutorial />
                  }/>

                  <Route path="/faq" name="Faq" render={
                    ()=><AsyncFaq />
                  }/>

                  <Route path='/coaches-corner' name="Coaches Corner" render={
                    ()=><AsyncCoachesCorner />
                  }/>



                  <Route path='/fitness-communities' name="Fitness Communities" render={
                    ()=><AsyncFitnessCommunities />
                  }/>
                  {!this.props.auth.token &&
                    <Fragment>
                      <h2 className="text-center">Verifying your log in status now</h2>
                      <div className="position-relative preloader-header">
                        <div className="showbox">
                          <div className="loader">
                            <svg className="circular" viewBox="25 25 50 50">
                              <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  }
                  {this.props.auth.user.created_at &&
                    <Fragment>
                      {moment(this.props.auth.user.created_at).isBefore(moment().subtract(30, 'days')) ? (
                        <Redirect from="/" to="/dashboard"/>
                      ) : (
                        <Redirect from="/" to="/tutorial"/>
                      )}
                    </Fragment>
                  }
                  <Redirect from="/attendance" to="/attendance/general"/>
                </Switch>
              </Suspense>
            </Container>
            {/* authorization modal */}
            <Modal 
              backdrop="static"
              isOpen={this.state.modal} 
              toggle={this.toggle}>
              <ModalHeader>Sign In</ModalHeader>
              <ModalBody>
                You're almost there! Please sign into btwb.com first.
              </ModalBody>
              <ModalFooter>
                <Button color="primary" href={REDIRECT_URL + '?to=' + window.location.hash.replace('#', '')}>Sign In</Button>
              </ModalFooter>
            </Modal>
            {/* Error Modal */}

            <Modal 
              isOpen={this.state.errorModal} 
              toggle={this.errorToggle} >
              <ModalHeader 
                className="bg-danger"
                // color="danger"
                toggle={this.errorToggle}>:(</ModalHeader>
              <ModalBody>
                {this.props.general.error}
                <p className="mb-0 mt-2">Code: <strong>{this.props.general.errorCode}</strong></p>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.errorToggle}>Close</Button>
              </ModalFooter>
            </Modal>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    member: state.member,
    performance: state.performance,
    general: state.general,
    extras: state.extras,
    planning: state.planning
  }
}

// All our fetches should be wrapped in an auth checker first
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // fukin auth
    authCheck: () => {
      return dispatch( authCheck() );
    },
    switchScope: (arg) => {
      return dispatch( switchScope(arg) );
    },
    logout: () => {
      return dispatch( logout() );
    },

    // Member detail specifics
    getMemberDetail: (id) => {
      return dispatch( authCheck(getMemberDetail.bind(this, id)) );
    },

    // analytics
    getGeneral: () => {
      return dispatch( authCheck(getGeneral) );
    },

    // general
    error: (message) => {
      return dispatch( error(message) );
    },
    dashboardFetch: (timeframe) => {
      return dispatch( authCheck(dashboardFetch.bind(this, timeframe)) );
    },
    toggleEmoji: (emoji, workoutSession) => {
      return dispatch( authCheck(toggleEmoji.bind(this, emoji,  workoutSession)) );
    },
    getComments: (id) => {
      return dispatch( authCheck(getComments.bind(this, id)) )
    },

    // testing
    getStatus: () => {
      return dispatch( authCheck(getStatus) )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Full);

import {
	GET_MBO_ACTIVITY,
	GET_MBO_SETTINGS,
	SET_MBO_SETTINGS,
	GET_MBO_AUTH_LINK,
	SET_MBO_MEMBER,
	GET_MBO_PROGRAMS,
	LINK_MBO_PROGRAMS,
	UPDATE_MBO_PROGRAM,
	GET_MBO_MANAGE,
	SYNC_MEMBERS,
	MBO_MEMBER_SYNC_EVENT,
	GET_MBO_UNLINKED,
	GET_BTWB_UNLINKED,
	LINK_MBO_MEMBERS,
	UNLINK_MBO_MEMBER,
	SEARCH_MBO_UNLINKED,
	GET_MBO_DUPLICATES,
	GET_MBO_IMPORT_STATE,
	CHECK_SELECTED_MEMBER,
	REFRESH_STATUS,
	MBO_EMAIL_INVITE
} from '../constants';

const initState = {
	fetching: false,
	syncState: 'on',
	lockState: 'off',
	options: {
		autoAdd: true,
		autoRemove: true,
		linked: false
	},
	programs: [],
	authFlow: false,
	mboId: '',
	username: '',
	url: '',
	programs: [],
	activity: [],
	membersManage: [],
	unlinkedMboMembers: [],
	unlinkedBtwbMembers: [],
	queryResponse: [],
	emailList: [],
	isImporting: false,
	refreshStatus: ''
}

const data = (state = initState, action) => {
	switch(action.type) {
		case GET_MBO_ACTIVITY:
			return Object.assign({}, state, {
				fetching: action.fetching,
				activity: action.activity ? action.activity : state.activity
			})
			break;
		case GET_MBO_SETTINGS:
			return Object.assign({}, state, {
				fetching: action.fetching,
				username: action.username ? action.username : state.username,
				mboId: action.mboId ? action.mboId : state.mboId,
				authFlow: action.authFlow ? action.authFlow : false,
				options: {
					autoAdd: action.autoAdd ? action.autoAdd : state.options.autoAdd,
					autoRemove: action.autoRemove ? action.autoRemove : state.options.autoRemove,
					linked: action.linked ? action.linked : state.options.linked
				},
				programs: action.programs ? action.programs : state.programs,
				syncState: action.syncState ? action.syncState : state.syncState,
				lockState: action.lockState ? action.lockState : state.lockState
			})
			break;
		case SET_MBO_SETTINGS: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				options: {
					autoAdd: 'autoAdd' in action ? action.autoAdd : state.options.autoAdd,
					autoRemove: 'autoRemove' in action ? action.autoRemove : state.options.autoRemove,
					linked: 'linked' in action ? action.linked : state.options.linked
				},
				syncState: action.syncState ? action.syncState : state.syncState,
				lockState: action.lockState ? action.lockState : state.lockState
			});
		case SYNC_MEMBERS: 
			return Object.assign({}, state, {
				fetching: action.fetching
			});
		case GET_MBO_AUTH_LINK:
			return Object.assign({}, state, {
				fetching: action.fetching,
				authFlow: action.authFlow ? action.authFlow : false,
				url: action.url ? action.url : state.url
			})
		case SET_MBO_MEMBER: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				mboId: action.mboId ? action.mboId : ''
			})
		case GET_MBO_PROGRAMS: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				programs: action.programs ? action.programs : state.programs
			})
		case LINK_MBO_PROGRAMS: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				programs: action.programs ? action.programs : state.programs
			});
		case UPDATE_MBO_PROGRAM: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				programs: action.programs ? action.programs : state.programs
			})
		case GET_MBO_MANAGE:
			return Object.assign({}, state, {
				fetching: action.fetching,
				membersManage: action.members
			})
		case MBO_MEMBER_SYNC_EVENT: 
			return Object.assign({}, state, {
				fetching: action.fetching
			})
		case GET_MBO_UNLINKED: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				unlinkedMboMembers: action.unlinkedMboMembers
			})
		case GET_BTWB_UNLINKED:
			return Object.assign({}, state, {
				fetching: action.fetching,
				unlinkedBtwbMembers: action.unlinkedBtwbMembers
			})
		case LINK_MBO_MEMBERS:
			return Object.assign({}, state, {
				fetching: action.fetching
			})
		case UNLINK_MBO_MEMBER:
			return Object.assign({}, state, {
				fetching: action.fetching
			})
		case SEARCH_MBO_UNLINKED: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				queryResponse: action.queryResponse
			})
		case GET_MBO_DUPLICATES: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				emailList: action.emailList
			})
		case GET_MBO_IMPORT_STATE:
			return Object.assign({}, state, {
				fetching: action.fetching,
				isImporting: action.isImporting ? action.isImporting : state.isImporting
			})
		case CHECK_SELECTED_MEMBER: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				membersManage: Array.isArray(action.members) ? action.members : state.membersManage
			})
		case REFRESH_STATUS:
			return Object.assign({}, state, {
				refreshStatus: action.refreshStatus
			})
		case MBO_EMAIL_INVITE: 
			return Object.assign({}, state, {
				fetching: action.fetching
			})
		default:
			return state;
	}
}

export default data;
import {
  GET_BODY_COMP,
  GET_BODY_COMP_DASHBOARD,
  RESET_BODY_COMP,
  GET_WEIGH_INS,
  GET_TARGETS,
  GET_PROGRESS
} from '../constants/constants';

const initState = {
  fetching: false,
  weighIns: {
    recent: 62,
    not: 101
  },
  targets: {
    set: 55,
    not: 42,
    recently: 11,
    due: 22,
    week: 5,
    missed: 2
  },
  progress: {
    weight: [],
    bodyFat: [],
    leanMass: []
  },
  progressPage: {
    members: [],
    date: undefined
  },
  bodyComp: [],
  pieChartDataWeighIns: {
    datasets: [{
      data: [10, 30],
      backgroundColor: ['#4dbd74', '#f86c6b'],
      hoverBackgroundColor: ['#2c6b42', '#a74948']
    }],
    labels: [
      'Have',
      'Have Not'
    ]
  },
  pieChartDataTargets: {
    datasets: [{
      data: [30, 30],
      backgroundColor: ['#4dbd74', '#f86c6b'],
      hoverBackgroundColor: ['#2c6b42', '#a74948']
    }],
    labels: [
      'Target Set',
      'Not Set'
    ]
  },
  graphOptions: {
    gender: 'all',
    filter: 'weight',
    age: 'all'
  },
  graph: {},
  weighInsPage: {
    fetching: false,
    recent: [],
    noWeighIns: []
  },
  targetsRecentlySetUpcoming: {
    recentlySet: [],
    upcoming: []
  },
  targetsHitsMisses: {
    hits: [],
    misses: []
  },
  targetsHaveDont: {
    have: [],
    dont: []
  }
}

const bodyComp = (state = initState, action) => {
  switch(action.type) {
    case GET_BODY_COMP:
      return Object.assign({}, state, {
        fetching: action.fetching,
        bodyComp: action.bodyComp ? action.bodyComp : []
      });
    case GET_BODY_COMP_DASHBOARD:
      return Object.assign({}, state, {
        weighIns: {
          recent: action.weighIns.recent ? action.weighIns.recent : state.weighIns.recent,
          not: action.weighIns.not ? action.weighIns.not : state.weighIns.not
        },
        targets: {
          set: action.targets.set ? action.targets.set : state.targets.set,
          not: action.targets.not ? action.targets.not : state.targets.not,
          recently: action.targets.recently ? action.targets.recently : state.targets.recently,
          due: action.targets.due ? action.targets.due : state.targets.due,
          week: action.targets.week ? action.targets.week : state.targets.week,
          missed: action.targets.missed ? action.targets.missed : state.targets.missed
        },
        progress: {
          weight: action.progress.weight ? action.progress.weight : state.progress.weight,
          bodyFat: action.progress.bodyFat ? action.progress.bodyFat : state.progress.bodyFat,
          leanMass: action.progress.leanMass ? action.progress.leanMass : state.progress.leanMass
        },
        graph: action.graph ? action.graph : state.graph
      });
    case GET_WEIGH_INS:
      return Object.assign({}, state, {
        weighInsPage: {
          fetching: action.fetching ? action.fetching : false,
          recent: action.recent ? action.recent : state.weighInsPage.recent,
          noWeighIns: action.noWeighIns ? action.noWeighIns : state.weighInsPage.noWeighIns
        }
      })
    case GET_TARGETS:
    
      return Object.assign({}, state, {
        targetsRecentlySetUpcoming: {
          recentlySet: action.recentlySet ? action.recentlySet : state.targetsRecentlySetUpcoming.recentlySet,
          upcoming: action.upcoming ? action.recentlySet : state.targetsRecentlySetUpcoming.upcoming
        },
        targetsHitsMisses: {
          hits: action.hits ? action.hits : state.targetsHitsMisses.hits,
          misses: action.misses ? action.misses : state.targetsHitsMisses.misses,
        },
        targetsHaveDont: {
          have: action.have ? action.have : state.targetsHaveDont.have,
          dont: action.dont ? action.dont : state.targetsHaveDont.dont
        }
      })
    case GET_PROGRESS: 
      return Object.assign({}, state, {
        progressPage: {
          date: action.date ? action.date : state.progressPage.date,
          members: action.members ? action.members: state.progressPage.members
        }
      })
    case RESET_BODY_COMP:
      return Object.assign({}, state, {
        bodyComp: [],
        weighIns: {
          recent: 0,
          not: 0
        },
        targets: {
          set: 0,
          not: 0,
          recently: 0,
          due: 0,
          week: 0,
          missed: 0
        },
        progress: {
          weight: [],
          bodyFat: [],
          leanMass: []
        },
        pieChartDataWeighIns: {
          datasets: [{
            data: [0, 0],
            backgroundColor: ['#4dbd74', '#f86c6b'],
            hoverBackgroundColor: ['#2c6b42', '#a74948']
          }],
          labels: [
            'Have',
            'Have Not'
          ]
        },
        pieChartDataTargets: {
          datasets: [{
            data: [0, 0],
            backgroundColor: ['#4dbd74', '#f86c6b'],
            hoverBackgroundColor: ['#2c6b42', '#a74948']
          }],
          labels: [
            'Target Set',
            'Not Set'
          ]
        },
        graphOptions: {
          gender: 'all',
          filter: 'weight',
          age: 'all'
        },
        graph: {},
        weighInsPage: {
          fetching: false,
          recent: [],
          noWeighIns: []
        },
        targetsRecentlySetUpcoming: {
          recentlySet: [],
          upcoming: []
        },
        targetsHitsMisses: {
          hits: [],
          misses: []
        },
        targetsHaveDont: {
          have: [],
          dont: []
        }
      })
    default:
      return state
  }
}

export default bodyComp;
import { 
  ERROR,
  DASHBOARD_FETCH,
  RESET_GENERAL
} from '../constants/constants';

const initState = {
  error: false,
  loading: false,
  dashboard: {}
}

const general = (state = initState, action) => {
  switch(action.type) {
    case ERROR: 
      return Object.assign({}, state, {
        error: action.message,
        errorCode: action.code ? action.code.message : ''
      });
    case DASHBOARD_FETCH:
      return Object.assign({}, state, {
        loading: action.loading,
        // Catch this earlier -- error case is calusing it to fall through
        dashboard: action.dashboard ? action.dashboard : {}
      });

    case RESET_GENERAL:
      return Object.assign({}, state, {
        error: false,
        loading: false,
        dashboard: {}
      });
    default:
      return state;
  }
  return state;
}

export default general;

// Auth stuff
export const NOT_AUTH = 'NOT_AUTH';
export const HAS_AUTH = 'HAS_AUTH';
export const GET_USER = 'GET_USER';
export const SWITCH_SCOPE = 'SWITCH_SCOPE';
export const UPDATE_COACHING_TEAM = 'UPDATE_COACHING_TEAM';

// data tables
export const GET_GENERAL = 'GET_GENERAL';
export const GET_ATTENDANCE = 'GET_ATTENDANCE';
export const GET_ENGAGEMENT = 'GET_ENGAGEMENT';
export const GET_FITNESS_LEVEL = 'GET_FITNESS_LEVEL';
export const GET_BODY_COMP = 'GET_BODY_COMP';
export const GET_LIFTS = 'GET_LIFTS';
export const GET_MAX_LIFTS = 'GET_MAX_LIFTS';
export const GET_PREV_MAX_LIFTS = 'GET_PREV_MAX_LIFTS';
export const GET_PR = 'GET_PR';
export const GET_INTERVALS = 'GET_INTERVALS';

// Member edits
export const GET_MANAGE ='GET_MANAGE';
export const GET_PENDING ='GET_PENDING';
export const INVITE_MEMBER = 'INVITE_MEMBER';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const UPDATE_PENDING = 'UPDATE_PENDING';
export const UPDATE_MEMBER_ROLE = 'UPDATE_MEMBER_ROLE';
export const GET_MEMBER_CODE = 'GET_MEMBER_CODE';
export const UPDATE_MEMBER_CODE = 'UPDATE_MEMBER_CODE';
export const SENDING_CSV_FILE = 'SENDING_CSV_FILE';
export const GETTING_CSV_FILE = 'GETTING_CSV_FILE';

// Member Detail
export const GET_MEMBER = 'GET_MEMBER';

// activity
export const GET_ACTIVITY_WOD = 'GET_ACTIVITY_WOD';
export const GET_ACTIVITY_FEED = 'GET_ACTIVITY_FEED';

// General events (IE don't fit in any specific boxes)
export const ERROR = 'ERROR'; // General error w/ attached messaging
export const DASHBOARD_FETCH = 'DASHBOARD_FETCH';
export const TOGGLE_EMOJI = 'TOGGLE_EMOJI';

// Settings
export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_PRIVACY = 'GET_PRIVACY';
export const GET_PREFS = 'GET_PREFS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

// Extras
export const GET_EXTRAS = 'GET_EXTRAS';

// Planning
export const GET_PLANNING = 'GET_PLANNING';
export const GET_MEMBER_PLANNING = 'GET_MEMBER_PLANNING';

// Body Comp
export const GET_BODY_COMP_DASHBOARD = 'GET_BODY_COMP_DASHBOARD';
export const GET_WEIGH_INS = 'GET_WEIGH_INS';
export const GET_TARGETS = 'GET_TARGETS';
export const GET_PROGRESS = 'GET_PROGRESS';

// Resets for each reducer
export const RESET_ANALYTICS = 'RESET_ANALYTICS';
export const RESET_ATTENDANCE = 'RESET_ATTENDANCE';
export const RESET_AUTH = 'RESET_AUTH';
export const RESET_BODY_COMP = 'RESET_BODY_COMP';
export const RESET_ENGAGEMENT = 'RESET_ENGAGEMENT';
export const RESET_EXTRAS = 'RESET_EXTRAS';
export const RESET_GENERAL = 'RESET_GENERAL';
export const RESET_MEMBERS = 'RESET_MEMBERS';
export const RESET_PERFORMANCE = 'RESET_PERFORMANCE';
export const RESET_PLANNING = 'RESET_PLANNING';
export const RESET_SETTINGS = 'RESET_SETTINGS';

// Mind Body Online Edits
export const GET_MBO_ACTIVITY = 'GET_MBO_ACTIVITY';
export const GET_MBO_SETTINGS = 'GET_MBO_SETTINGS';
export const SET_MBO_SETTINGS = 'SET_MBO_SETTINGS';
export const GET_MBO_AUTH_LINK = 'GET_MBO_AUTH_LINK';
export const SET_MBO_MEMBER = 'SET_MBO_MEMBER';
export const GET_MBO_PROGRAMS = 'GET_MBO_PROGRAMS';
export const LINK_MBO_PROGRAMS = 'LINK_MBO_PROGRAMS';
export const SYNC_MEMBERS = 'SYNC_MEMBERS';
export const LINK_MBO_MEMBERS = 'LINK_MBO_MEMBERS';
export const UPDATE_MBO_PROGRAM = 'UPDATE_MBO_PROGRAM';
export const GET_MBO_MANAGE = 'GET_MBO_MANAGE';
export const MBO_MEMBER_SYNC_EVENT = 'MBO_MEMBER_SYNC_EVENT';
export const GET_MBO_UNLINKED = 'GET_MBO_UNLINKED';
export const GET_BTWB_UNLINKED = 'GET_BTWB_UNLINKED';
export const UNLINK_MBO_MEMBER = 'UNLINK_MBO_MEMBER';
export const SEARCH_MBO_UNLINKED = 'SEARCH_MBO_UNLINKED';
export const GET_MBO_DUPLICATES = 'GET_MBO_DUPLICATES';
export const GET_MBO_IMPORT_STATE = 'GET_MBO_IMPORT_STATE';
export const CHECK_SELECTED_MEMBER = 'CHECK_SELECTED_MEMBER';
export const REFRESH_STATUS = 'REFRESH_STATUS';
export const MBO_EMAIL_INVITE = 'MBO_EMAIL_INVITE';

// Mind Body Online Edits
export const GET_PIKE13_ACTIVITY = 'GET_PIKE13_ACTIVITY';
export const GET_PIKE13_SETTINGS = 'GET_PIKE13_SETTINGS';
export const SET_PIKE13_SETTINGS = 'SET_PIKE13_SETTINGS';
export const GET_PIKE13_AUTH_LINK = 'GET_PIKE13_AUTH_LINK';
export const SET_PIKE13_MEMBER = 'SET_PIKE13_MEMBER';
export const GET_PIKE13_PROGRAMS = 'GET_PIKE13_PROGRAMS';
export const LINK_PIKE13_PROGRAMS = 'LINK_PIKE13_PROGRAMS';
export const SYNC_PIKE13_MEMBERS = 'SYNC_PIKE13_MEMBERS';
export const LINK_PIKE13_MEMBERS = 'LINK_PIKE13_MEMBERS';
export const UPDATE_PIKE13_PROGRAM = 'UPDATE_PIKE13_PROGRAM';
export const GET_PIKE13_MANAGE = 'GET_PIKE13_MANAGE';
export const PIKE13_MEMBER_SYNC_EVENT = 'PIKE13_MEMBER_SYNC_EVENT';
export const GET_PIKE13_UNLINKED = 'GET_PIKE13_UNLINKED';
export const GET_BTWB_PIKE13_UNLINKED = 'GET_BTWB_PIKE13_UNLINKED';
export const UNLINK_PIKE13_MEMBER = 'UNLINK_PIKE13_MEMBER';
export const SEARCH_PIKE13_UNLINKED = 'SEARCH_PIKE13_UNLINKED';
export const GET_PIKE13_DUPLICATES = 'GET_PIKE13_DUPLICATES';
export const GET_PIKE13_IMPORT_STATE = 'GET_PIKE13_IMPORT_STATE';
export const PIKE13_CHECK_SELECTED_MEMBER = 'PIKE13_CHECK_SELECTED_MEMBER';
export const PIKE13_REFRESH_STATUS = 'PIKE13_REFRESH_STATUS';
export const PIKE13_EMAIL_INVITE = 'PIKE13_EMAIL_INVITE';
export const CLAIM_PIKE13_BUSINESS = 'CLAIM_PIKE13_BUSINESS';
export const GET_PIKE13_BUSINESS = 'GET_PIKE13_BUSINESS';

// Fintess Communities
export const GET_FITNESS_COMMUNITY_STATUS = 'GET_FITNESS_COMMUNITY_STATUS';

// determined in Webpack Config
export const urlDev = 'https://coach-data.prod.btwb.com';
export const urlAuthDev = API_AUTH_URL;

export const errorCatch = function(res, dispatch) {

  if(!res.ok) {
    if(res.status === 403 || res.status === 401) {
      dispatch({
        type: NOT_AUTH
      });
      return {error: true};
    } else {
      console.log(res)
      dispatch({
        type: ERROR,
        message: 'Failed to get necessary information off our URL, please try again later.',
        code: {message: res.status}
      })
      // return {error: true};
      throw new Error(res.status);
    }
  }
  
  return res.json();
}

import { 
  NOT_AUTH,
  HAS_AUTH,
  GET_USER,
  RESET_AUTH,
  SWITCH_SCOPE,
  UPDATE_COACHING_TEAM
} from '../constants/constants';

const initState = {
  token: false,
  user: {},
  isFetching: false,
  activeScope: undefined,
  programs: [],
  gymType: '',
  coachingTeamName: ''
}

const auth = (state = initState, action) => {
  switch (action.type) {

    case NOT_AUTH: 
      return Object.assign({}, state, {
        token: false,
        user: '',
        isFetching: false
      });
    case HAS_AUTH:
      return Object.assign({}, state, {
        token: action.token
      });
    case GET_USER: 
      return Object.assign({}, state, {
        user: action.user ? action.user : state.user,
        isFetching: action.isFetching,
        activeScope: action.activeScope,
        id: action.id,
        gymType: action.gymType ? action.gymType : state.gymType
      });
    case SWITCH_SCOPE:
      return Object.assign({}, state, {
        activeScope: action.activeScope,
        programs: action.programs.length > 0 ? action.programs : (action.gymType === 'CoachingTeam' ? state.programs : []),
        gymType: action.gymType ? action.gymType : state.gymType
      });
    case RESET_AUTH:
      return Object.assign({}, state, {
        token: false,
        user: {},
        isFetching: false,
        activeScope: ''
      });
    case UPDATE_COACHING_TEAM:
      return Object.assign({}, state, {
        programs: action.programs.length > 0 ? action.programs : (action.gymType === 'CoachingTeam' ? state.programs : []),
        activeScope: action.activeScope ? action.activeScope : state.activeScope,
        gymType: action.gymType ? action.gymType : state.gymType,
        id: action.id ? action.id : state.id,
        user: action.user ? action.user : state.user,
        coachingTeamName: action.coachingTeamName ? action.coachingTeamName : action.coachingTeamName
      })

    default:
      return state
  }
  return state;
};

export default auth;
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import {withRouter} from 'react-router-dom';

class GoogleAnalyticsLink extends Component {
	constructor(props) {
		super(props);

	}

	onClick(e) {
		e.preventDefault();

		ReactGA.event({
		  category: this.props.category,
		  action: 'click',
		  label: this.props.label
		});

		if(this.props.newTab) {
			return window.open(this.props.link, '_blank');
		}
		return window.location = this.props.link;

	}

	render() {
		return(
			<a 
				className={'remove-underline-ga' + (this.props.classes ? ' ' + this.props.classes : '')} 
				onClick={this.onClick.bind(this)} 
				href={this.props.link}>
				{this.props.children}
			</a>
		)
	}
}

export default withRouter(GoogleAnalyticsLink);
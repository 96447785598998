import {
  GET_MEMBER,
  urlDev,
  errorCatch,
  NOT_AUTH, 
  HAS_AUTH 
} from '../constants/constants';
import { ERROR } from '../constants/constants';

export const getMemberDetail = (id) => {

  return(dispatch, getState) => {
    const ourState = getState();
    dispatch({
      type: GET_MEMBER,
      fetching: true
    });

    fetch(urlDev + '/marlo/' + id + '/marlo_fitness_level', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        fitnessLevel: response.cards
      })
    })

    fetch(urlDev + '/marlo/' + id + '/marlo_member_calendar', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        calendar: response.cards
      });
    })

    fetch(urlDev + '/marlo/' + id + '/marlo_macros_analyze', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        macrosAnalyze: response.cards
      });
    })

    fetch(urlDev + '/marlo/resolvers/cards/members/profile/activity?id=all&member_id=' + id + '&type=WorkoutSession', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        workouts: response.cards
      });
    })

    fetch(urlDev + '/marlo/' + id + '/marlo_training_day', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        trainingDays: response.cards
      });
    })

    fetch(urlDev + '/marlo/' + id + '/marlo_modalities', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        modalities: response.cards
      });
    })

    fetch(urlDev + '/marlo/' + id + '/marlo_imbalance_category?category=overhead', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        overhead: response.cards
      });
    })

    fetch(urlDev + '/marlo/' + id + '/marlo_imbalance_category?category=olympiclifts', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        olympiclifts: response.cards
      });
    })

    fetch(urlDev + '/marlo/' + id + '/marlo_imbalance_category?category=powerlifts', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        powerlifts: response.cards
      });
    })

    fetch(urlDev + '/marlo/' + id + '/marlo_imbalance_category?category=squats', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        squats: response.cards
      });
    })

    fetch(urlDev + '/marlo/' + id + '/marlo_imbalance_category?category=endurance', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        endurance: response.cards
      });
    })

    fetch(urlDev + '/marlo/' + id + '/marlo_imbalance_category?category=speed', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        speed: response.cards
      });
    })

    fetch(urlDev + '/marlo/' + id + '/marlo_weigh_ins', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      // console.log(response)
      return dispatch({
        type: GET_MEMBER,
        weighIns: response.cards
      });
    })

    fetch(urlDev + '/marlo/resolvers/cards/edit_profile?id=all&member_id=' + id, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      console.log(response.email);
      return dispatch({
        type: GET_MEMBER,
        email: response.email
      });
    })
  }
}

export const getMemberCalendar = (date) => {
  return (dispatch, getState) => {
    let queryString = '';
    if(date) {
      queryString = '?date=' + date;
    }

    dispatch({
      type: GET_MEMBER,
      fetching: true
    })

    fetch(urlDev + '/marlo/' + id + '/marlo_member_calendar' + queryString, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        calendar: response.cards,
        fetching: false
      });
    })

  }
}

export const getMacrosDay = (id, date) => {
  return (dispatch, getState) => {
    const ourState = getState();
    dispatch({
      type: GET_MEMBER,
      fetching: true,
    })

    fetch(urlDev + '/marlo/' + id + '/marlo_macros_day' + (date ? '?date=' + date : ''), {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        macros: response.cards,
        fetching: false
      });
    })
  }
}

export const getMacros = (memberId) => {
  return(dispatch, getState) => {
    const ourState = getState();

   return fetch(urlDev + '/v1/macros/members/' + memberId + '/daily_targets', {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + ourState.auth.token
        })
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      return dispatch({
        type: GET_MEMBER,
        macrosTarget: response.cards[0].daily_targets
      })
    });
  }
}

export const createMacros = (targetObject) => {
  return(dispatch, getState) => {
    const ourState = getState();

    fetch( '/v1/macros/members/' + memberId + '/daily_targets/',{
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      errorCatch(res, dispatch);
    }).then(response => {
      // console.log(response)
    })
  }
}

export const updateMacros = (targetObject, memberId) => {
  return(dispatch, getState) => {
    const ourState = getState();

    let newObject = {
      name: targetObject.name,
      protein: targetObject.protein,
      carbs: targetObject.carbs,
      fat: targetObject.fat,
      targetCals: targetObject.cals,
      active: targetObject.active
    }


    // console.log(targetObject, memberId)
    // return;
    fetch(urlDev + '/v1/macros/members/' + memberId + '/daily_targets/' + targetObject.key,{
      method: 'PATCH',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(newObject)
    }).then(res => {
      errorCatch(res, dispatch);
    }).then(response => {
      // console.log(response)
    })
  }
}

export const removeMacros = (targetObject, memberId) => {
  return(dispatch, getState) => {
    const ourState = getState();

    fetch(urlDev + '/v1/macros/members/' + memberId + '/daily_targets/' + targetObject.key, {
      method: 'DELETE',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then(res => {
      errorCatch(res, dispatch);
    }).then(response => {
      // console.log(response);
    })
  }
}

export const addMacros = (targetObject, memberId) => {
  return(dispatch, getState) => {
    const ourState = getState();

    let newObject = {
      name: targetObject.name,
      protein: targetObject.protein,
      carbs: targetObject.carbs,
      fat: targetObject.fat,
      targetCals: targetObject.cals,
      active: targetObject.active
    }

    fetch(urlDev + '/v1/macros/members/' + memberId + '/daily_targets', {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(newObject)
    }).then(res => {
      errorCatch(res, dispatch);
    }).then(response => {
      // console.log(response);
    })
  }
}
/* Endpoints

marlo_coaching
marlo_explore_workouts
marlo_featured_workouts
marlo_fitness_level
marlo_fitness_level_category
marlo_group_settings
marlo_home
marlo_home_anon
marlo_imbalance_category
marlo_imbalances
marlo_leaderboard_category
marlo_leaderboard_divisions
marlo_leaderboards
marlo_lifestyle
marlo_lifting
marlo_macros_analyze
marlo_macros_day
marlo_member_activities
marlo_member_benchmarks
marlo_member_calendar
marlo_member_settings
marlo_modalities
marlo_modality_detail
marlo_notifications
marlo_panel_header
marlo_profile
marlo_scope_memberships
marlo_scope_settings
marlo_search
marlo_squads
marlo_stream_activities
marlo_streams
marlo_training_day
marlo_weigh_ins
marlo_wods*/
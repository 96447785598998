import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Router, Route, Switch} from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reducer from './reducers';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
require('./i18n');

// Styles
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Temp fix for reactstrap
// import '../scss/core/_dropdown-menu-right.scss';
require('../scss/core/_dropdown-menu-right.scss');
// Import Main styles for this application
// import '../scss/style.scss';
require('../scss/style.scss')

// Containers
import Full from './containers/Full/';

if(window.location.host !== 'coach-dev.crossfitbtwb.com' && window.location.host !== 'coach-dev.btwb.com') {
  // return;
  if(window.location.protocol === 'http:') {
    if(window.location.host.indexOf('stage') > -1) {
      window.location.href = 'https://coach-stage.btwb.com'/* + window.location.host*/;
    } else {
      window.location.href = 'https://coach.btwb.com'/* + window.location.host*/;  
    }
    
  } else if (window.location.host === 'coach-stage.crossfitbtwb.com') {
    window.location.href = 'https://coach-stage.btwb.com';
  } else if (window.location.host === 'coach.crossfitbtwb.com') {
    window.location.href = 'https://coach.btwb.com';
  }
}

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('../firebase-messaging-sw.js')
//   .then(function(registration) {
//     console.log('Registration successful, scope is:', registration.scope);
//   }).catch(function(err) {
//     console.log('Service worker registration failed, error:', err);
//   });
// }

const loggerMiddleware = createLogger();

const store = createStore(reducer, applyMiddleware(
  thunkMiddleware/*,
  loggerMiddleware*/
));

ReactDOM.render((
  <Provider store={store}>
    <HashRouter>
      <Switch>
        <Route path="/" name="Home" component={Full} />
      </Switch>
    </HashRouter>
  </Provider>
), document.getElementById('root'));

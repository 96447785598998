import {
  GET_ENGAGEMENT,
  RESET_ENGAGEMENT
} from '../constants/constants';



const initState = {
  fetching: false,
  members: [],
  top: {
    members: []
  }
}

const engagement = (state = initState, action) => {
  switch(action.type) {
    case GET_ENGAGEMENT:
      return Object.assign({}, state, {
        fetching: action.fetching ? action.fetching : false,
        members: action.engagement ? action.engagement : [],
        top: action.top ? action.top : state.top
      });
    case RESET_ENGAGEMENT:
      return Object.assign({}, state, {
        fetching: false,
        members: [],
        top: {
          members: []
        }
      })
    default:
      return state;
  }
}

export default engagement;
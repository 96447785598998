import {
  GET_ATTENDANCE,
  RESET_ATTENDANCE
} from '../constants/constants';

const initState = {
  members: [],
  committed: {},
  fetching: false,
  mia: {
    members: []
  }
}

const attendance = (state = initState, action) => {
  switch(action.type) {
    case GET_ATTENDANCE:
      return Object.assign({}, state, {
        fetching: action.fetching ? action.fetching : false,
        members: action.members ? action.members : state.members,
        committed: action.committed ? action.committed : state.committed,
        mia: action.mia ? action.mia : state.mia
      });
    case RESET_ATTENDANCE:
      return Object.assign({}, state, {
        members: [],
        committed: {},
        fetching: false,
        mia: {
          members: []
        }
      })
    default:
      return state
  }
}

export default attendance;
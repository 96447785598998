import { 
  GET_EXTRAS,
  RESET_EXTRAS
} from '../constants/constants';

const initState = {
  wordpress: {},
  webwidgets_config: {
    access_key: '',
    tracks: []
  }
}

const extras = (state = initState, action) => {
  switch(action.type){
   case GET_EXTRAS: 
    return Object.assign({}, state, {
      webwidgets_config: action.webwidgets_config ? action.webwidgets_config : state.webwidgets_config,
      wordpress: action.wordpress ? action.wordpress : state.wordpress
    })
   default: 
    return state;
  case RESET_EXTRAS:
    return Object.assign({}, state, {
      wordpress: {},
      webwidgets_config: {
        access_key: '',
        tracks: []
      }
    })
  }
  return state;
}

export default extras;

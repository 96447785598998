import {
  GET_SETTINGS,
  UPDATE_SETTINGS,
  GET_PRIVACY,
  GET_PREFS,
  RESET_SETTINGS
} from '../constants/constants';

const initState = {
  current: {
    name: '',
    address: {
      city: '',
      country: '',
      state: '',
      street: '',
      postal_code: ''
    },
    website_url: '',
    contact_email: '',
    phone_number: '',
    description: '',
    image: ''
  },
  privacy: {
    members: '',
    wod: '',
    activity: ''
  },
  prefs: {
    preferred_timezone: '',
    preferred_wod_display: 0
  },
  loading: false,
  sending: false
}

const settings = (state = initState, action) => {
  switch(action.type) {
    case GET_SETTINGS:
      return Object.assign({}, state, {
        current: action.current ? action.current : state.current,
        loading: action.loading
      });
    case UPDATE_SETTINGS:
      return Object.assign({}, state, {
        current: action.current ? action.current : state.current,
        privacy: action.privacy ? action.privacy : state.privacy,
        prefs: action.prefs ? action.prefs : state.prefs,
        loading: action.loading,
        sending: action.sending ? action.sending : false 
      });
    case GET_PRIVACY:
      return Object.assign({}, state, {
        privacy: action.privacy,
        loading: action.loading
      });
    case GET_PREFS:
    console.log(action.prefs)
      return Object.assign({}, state, {
        prefs: action.prefs,
        loading: action.loading
      });
    case RESET_SETTINGS:
      return Object.assign({}, state, {
        current: {
          name: '',
          address: {
            city: '',
            country: '',
            state: '',
            street: '',
            postal_code: ''
          },
          website_url: '',
          contact_email: '',
          phone_number: '',
          description: '',
          image: ''
        },
        privacy: {
          members: '',
          wod: '',
          activity: ''
        },
        prefs: {
          preferred_timezone: '',
          preferred_wod_display: 0
        },
        loading: false
      });
    default:
      return state;
  }
}

export default settings;

import {
  GET_PR,
  RESET_PERFORMANCE,
  GET_INTERVALS,
  GET_LIFTS,
  GET_MAX_LIFTS,
  GET_PREV_MAX_LIFTS,
  GET_FITNESS_LEVEL
} from '../constants';

const initState = {
  prBoard: [],
  min_date: '',
  intervals: [],
  intervalsMetadata: {},
  lifts: [],
  fetching: false,
  liftMetadata: {},
  fitnessLevel: [],
  maxLifts: {},
  prevMaxLifts: {}
};

const performance = (state = initState, action) => {
  switch(action.type) {
    case GET_FITNESS_LEVEL:
      return Object.assign({}, state, {
        fetching: action.fetching,
        fitnessLevel: action.fitnessLevel ? action.fitnessLevel : []
      });
    case GET_PR:
      return Object.assign({}, state, {
        prBoard: action.prBoard ? action.prBoard : state.prBoard
      });
    case GET_LIFTS:
      return Object.assign({}, state, {
        fetching: action.fetching,
        lifts: action.lifts ? action.lifts : [],
        liftMetadata: action.liftMetadata ? action.liftMetadata : state.liftMetadata
      });
    case GET_MAX_LIFTS:
      return Object.assign({}, state, {
        fetching: action.fetching,
        maxLifts: action.maxLifts ? action.maxLifts : state.maxLifts
      })
    case GET_PREV_MAX_LIFTS:
      return Object.assign({}, state, {
        prevMaxLifts: action.prevMaxLifts ? action.prevMaxLifts : state.prevMaxLifts
      })
    case GET_INTERVALS:
      return Object.assign({}, state, {
        fetching: action.fetching,
        intervals: action.intervals ? action.intervals : [],
        intervalsMetadata: action.intervalsMetadata ? action.intervalsMetadata : state.intervalsMetadata
      })
    case RESET_PERFORMANCE:
      return Object.assign({}, state, {
        prBoard: [],
        min_date: '',
        intervals: [],
        intervalsMetadata: {},
        lifts: [],
        liftMetadata: {},
        fitnessLevel: [],
        maxLifts: {},
        prevMaxLifts: {}
      });
    default:
      return state;
  }
}

export default performance;

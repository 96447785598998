import {
  GET_ACTIVITY_WOD,
  GET_ACTIVITY_FEED
} from '../constants';
import moment from 'moment';

const initState = {
  fetching: false,
  activityWod: {
    date: moment().format('YYYY-MM-DD'),
    tracks: {}
  },
  activityFeed: {
    activities: [],
    page: 1
  },
  activityPrs: {
    activities: [],
    page: 1
  }
}

const data = (state = initState, action) => {
  switch (action.type) {
    case GET_ACTIVITY_WOD: 
      return Object.assign({}, state, {
        fetching: action.fetching ? action.fetching : false,
        activityWod: {
          tracks: action.activityWod.tracks ? action.activityWod.tracks : state.activityWod.tracks,
          date: action.activityWod.date ? action.activityWod.date : state.activityWod.date
        }
      })
    case GET_ACTIVITY_FEED: 
      return Object.assign({}, state, {
        fetching: action.fetching ? action.fetching : false,
        activityFeed: action.activityFeed ? action.activityFeed : state.activityFeed,
        activityPrs: action.activityPrs ? action.activityPrs : state.activityPrs
      })
    default:
      return state;
  }
}

export default data;
import {
  GET_PLANNING,
  GET_MEMBER_PLANNING,
  RESET_PLANNING
} from '../constants/constants';


const initState = {
  loading: false,
  calendars: {},
  members: {
    members: [],
    planned: {}
  }
}

const settings = (state = initState, action) => {
  switch(action.type){
    case GET_PLANNING: 
      return Object.assign({}, state, {
        loading: action.loading,
        calendars: action.results
      })
    case GET_MEMBER_PLANNING:
      return Object.assign({}, state, {
        loading: action.loading,
        members: action.results
      })
    case RESET_PLANNING: 
      return Object.assign({}, state, {
        loading: false,
        calendars: {},
        members: {
          members: [],
          planned: {}
        }
      })
    default:
      return state
  }
}

export default settings;
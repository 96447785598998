
import { 
  HAS_AUTH, 
  NOT_AUTH,
  GET_USER,
  SWITCH_SCOPE,
  RESET_ANALYTICS,
  RESET_ATTENDANCE,
  RESET_AUTH,
  RESET_BODY_COMP,
  RESET_ENGAGEMENT,
  RESET_EXTRAS,
  RESET_GENERAL,
  RESET_MEMBERS,
  RESET_PERFORMANCE,
  RESET_PLANNING,
  RESET_SETTINGS,
  ERROR,
  UPDATE_COACHING_TEAM,
  errorCatch,
  urlAuthDev,
  urlDev
} from '../constants/constants';
// import jwt_decode from 'jwt-decode';
import rootReducer from '../reducers';


const checkForAuth = (user) => {
  // return;
  if(window.location.href.indexOf('coach-stage') > -1) {
    
    if(!user.site_admin && user.name !== 'Matt Egan') {
      return window.location.href = 'https://coach.btwb.com';
    }
  }
}


export const authCheck = (ourMainFunction) => {
  return (dispatch, getState) => {
    // return;
    
    let ourState = getState();
    let fullToken;
    if(window.location.search ) {
      if(window.location.search.indexOf('?access_token=') >= 0) {
        fullToken = window.location.search.split('?access_token=')[1];
        window.localStorage.setItem('tempToken', fullToken);

        window.history.pushState('', document.title, window.location.pathname);
      } else {
        return dispatch({
          type: NOT_AUTH
        });
      }
    } else if(window.localStorage.getItem('tempToken')) {

      fullToken = window.localStorage.getItem('tempToken');

    } else {
      return dispatch({
        type: NOT_AUTH
      });
    }
      
    dispatch({
      type: HAS_AUTH,
      token: fullToken
    });

    if(!ourMainFunction && Object.keys(getState().auth.user).length !== 0) return;
    // console.log(getState());
    if(Object.keys(ourState.auth.user).length !== 0) {
      // Redirect to prod IF not admin/me
      checkForAuth(ourState.auth.user);
      return dispatch(ourMainFunction());
    }
    return dispatch(getUser(ourMainFunction));
  }
}

export const getUser = (ourMainFunction) => {

  return (dispatch, getState) => {
    let ourState = getState();
    dispatch({
      type: GET_USER,
      isFetching: true
    });
    return fetch(urlAuthDev + '/userinfo', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then((res) => {
      return errorCatch(res, dispatch);
    }).then((response) => {
      if(response.error === true) return;
      
      let firstMembership = Object.keys(response.memberships)[0];
      let tempId = response.memberships[firstMembership].scope_id;

      // Redirect to prod IF not admin/me
      checkForAuth(response);

      if(response.memberships[firstMembership].scope_type === 'CoachingTeam') {
        // there's probs a more react way to handle this...

        fetch(urlDev + '/v1/coaching_teams/' + firstMembership, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + ourState.auth.token
          })
        }).then(res => {
          return errorCatch(res, dispatch);
        }).then(response2 => {
          let activeScope = firstMembership;

          if(response2.coaching_programs.length > 0) activeScope = response2.coaching_programs[0].scope;
          dispatch({
            type: UPDATE_COACHING_TEAM,
            programs: response2.coaching_programs,
            gymType: 'CoachingTeam',
            activeScope: activeScope,
            user: response,
            id: tempId,
            coachingTeamName: response.memberships[firstMembership].name
          })
          
          if(ourMainFunction) return dispatch(ourMainFunction());
          return;

        }).catch((err) => {
          console.log(err);
          dispatch({
            type: ERROR,
            message: 'Error with your coaching program'
          })
        })
      } else {
        dispatch({
          type: GET_USER,
          isFetching: false,
          user: response,
          id: tempId,
          gymType: response.memberships[firstMembership].scope_type,
          activeScope: firstMembership
        });
        
        if(ourMainFunction) return dispatch(ourMainFunction());
        return;
      }
    })/*.catch((error) => {
      console.log(error);
      dispatch({
        type: NOT_AUTH
      });
      dispatch({
        type: ERROR,
        message: 'Whoa, looks like we can\'t get your user! Please make sure you\'re an Administrator and try again.'
      })
    })*/;
  }
}

export const logout = () => {
  return (dispatch, getState) => {
    if(window.localStorage.getItem('tempToken')) {
      window.localStorage.removeItem('tempToken');
    }
    return dispatch({
      type: RESET_AUTH
    });
  }
}

export const switchScope = (scope) => {
  return (dispatch, getState) => {
    let ourState = getState();

    if(ourState.auth.activeScope === scope) return;

    // there's probs a more react way to handle this...
    const handleCoachingTeam = () => {
      return fetch(urlDev + '/v1/coaching_teams/' + scope, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + ourState.auth.token
        })
      }).then(res => {
        return errorCatch(res, dispatch);
      }).then(response => {
        let activeScope = scope;
        if(response.coaching_programs.length > 0) activeScope = response.coaching_programs[0].scope; 
        dispatch({
          type: UPDATE_COACHING_TEAM,
          programs: response.coaching_programs,
          gymType: ourState.auth.user.memberships[scope].scope_type,
          activeScope: activeScope,
          coachingTeamName: ourState.auth.user.memberships[scope].name
        })

        clearingFunction();
      }).catch((err) => {
        console.log(err);
        dispatch({
          type: ERROR,
          message: 'Error with your coaching program'
        })
      })
    }

    const clearingFunction = () => {
      dispatch({
        type: RESET_ANALYTICS
      });
      dispatch({
        type: RESET_ATTENDANCE
      });
      dispatch({
        type: RESET_BODY_COMP
      });
      dispatch({
        type: RESET_ENGAGEMENT
      });
      dispatch({
        type: RESET_EXTRAS
      });
      dispatch({
        type: RESET_GENERAL
      });
      dispatch({
        type: RESET_MEMBERS
      });
      dispatch({
        type: RESET_PERFORMANCE
      });
      dispatch({
        type: RESET_PLANNING
      });
      dispatch({
        type: RESET_SETTINGS
      });
    }

    if(!ourState.auth.user.memberships[scope]) {
      dispatch({
        type: SWITCH_SCOPE,
        activeScope: scope,
        gymType: 'CoachingTeam',
        programs: []
      });
      clearingFunction();
    } else if(ourState.auth.user.memberships[scope].scope_type === 'CoachingTeam') {
      handleCoachingTeam();
    } else {
      
      dispatch({
        type: SWITCH_SCOPE,
        gymType: ourState.auth.user.memberships[scope].scope_type,
        activeScope: scope,
        programs: []
      });
      clearingFunction();
    }
  }
}

import { 
  ERROR,
  DASHBOARD_FETCH,
  NOT_AUTH,
  TOGGLE_EMOJI,
  urlDev,
  errorCatch,
} from '../constants/constants';

export const error = (message) => {
  return (dispatch, getState) => {
    return dispatch({
      type: ERROR,
      message: message
    });
  }
}

export const dashboardFetch = (timeframe) => {
  return (dispatch, getState) => {

    let ourState = getState();

    let headers = new Headers({
      'Authorization': 'Bearer ' + ourState.auth.token
    });

    dispatch({
      type: DASHBOARD_FETCH,
      loading: true
    });

    if(!timeframe) timeframe = 'weekly';

    return fetch(urlDev + '/v1/reports/' + ourState.auth.activeScope + '/dashboard/general?' + 'window_size=' + timeframe, {
      method: 'GET',
      headers: headers
    }).then((res) => {
      return errorCatch(res, dispatch);
    }).then((response) => {
      if(response.error) return;
      return dispatch({
        type: DASHBOARD_FETCH,
        loading: false,
        dashboard: response
      });

    }).catch((err) => {
      return;
    })

  }
}

export const toggleEmoji = (emoji, workoutSession) => {
  return(dispatch, getState) => {
    let ourState = getState();

    let headers = new Headers({
      'Authorization': 'Bearer ' + ourState.auth.token
    });

    // console.log(emoji, workoutSession);
    // return;

    dispatch({
      type: TOGGLE_EMOJI,
      loading: true
    });

    return fetch(urlDev + '/v1/emojis?target=_workout_session_' + workoutSession + '&emoji=' + emoji, {
      headers: headers,
      method: 'POST'
    }).then(res => {
      return errorCatch(res, dispatch);
    }).then(response => {
      if (response.error) return;
      // console.log(response);
      dispatch({
        type: TOGGLE_EMOJI,
        loading: false
      });
      // we need the actual response from the server here -- can't use dispatched state
      return response;
    });
  }
}

export const getComments = (id) => {
  return (dispatch, getState) => {
    let ourState = getState();

    let headers = new Headers({
      'Authorization': 'Bearer' + ourState.auth.token
    });

    return fetch(urlDev + '/v1/comments?target=_workout_session_' + id, {
      headers: headers
    }).then(res => {
      return errorCatch(res, dispatch)
    }).then(response => {
      if(response.error) return;

      console.log(response);
    })
  }
}
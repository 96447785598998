import {
  GET_FITNESS_COMMUNITY_STATUS,
  errorCatch,
  urlAuthDev,
  urlDev
} from '../constants/constants';



export const getFitnessCommunityStatus = () => {

  return(dispatch, getState) => {
    let ourState = getState();

    fetch(urlDev + '/v1/gyms/' + ourState.auth.activeScope + '/fitness_communities', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + ourState.auth.token
      })
    }).then((response) => {
      return errorCatch(response, dispatch)
    }).then(res => {
      console.log(res)
      dispatch({
        type: GET_FITNESS_COMMUNITY_STATUS,
        fitness_community_status: res.account
      })
    });
  }
}
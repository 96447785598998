import {
  GET_MEMBER
} from '../constants/constants';

const initState = {
  fetching: false,
  calendar: [],
  notes: [],
  trainingDays: [],
  fitnessLevel: [],
  modalities: [],
  imbalances: [],
  workouts: [],
  realWorkouts: [],
  weighIns: [],
  macros: [],
  macrosAnalyze: [],
  macrosTarget: [],
  imbalancesByCategory: {
    olympiclifts: [],
    squats: [],
    endurance: [],
    speed: [],
    powerlifts: [],
    overhead: []
  },
  email: ''
}

const data = (state = initState, action) => {
  switch(action.type) {
    case GET_MEMBER:
      return Object.assign({}, state, {
        fetching: action.fetching ? action.fetching : false,
        calendar: action.calendar ? action.calendar : state.calendar,
        notes: action.notes ? action.notes : state.notes,
        trainingDays: action.trainingDays ? action.trainingDays : state.trainingDays,
        fitnessLevel: action.fitnessLevel ? action.fitnessLevel : state.fitnessLevel,
        modalities: action.modalities ? action.modalities : state.modalities,
        imbalances: action.imbalances ? action.imbalances : state.imbalances,
        imbalancesByCategory: {
          olympiclifts: action.olympiclifts ? action.olympiclifts : state.imbalancesByCategory.olympiclifts,
          endurance: action.endurance ? action.endurance : state.imbalancesByCategory.endurance,
          speed: action.speed ? action.speed : state.imbalancesByCategory.speed,
          powerlifts: action.powerlifts ? action.powerlifts : state.imbalancesByCategory.powerlifts,
          squats: action.squats ? action.squats : state.imbalancesByCategory.squats,
          overhead: action.overhead ? action.overhead : state.imbalancesByCategory.overhead
        },
        weighIns: action.weighIns ? action.weighIns : state.weighIns,
        workouts: action.workouts ? action.workouts : state.workouts,
        realWorkouts: action.realWorkouts ? action.worealWorkoutsrkouts : state.realWorkouts,
        macros: action.macros ? action.macros : state.macros,
        macrosAnalyze: action.macrosAnalyze ? action.macrosAnalyze : state.macrosAnalyze,
        macrosTarget: action.macrosTarget ? action.macrosTarget : state.macrosTarget,
        email: action.email ? action.email : state.email
      })

    default:
      return state;
  }
}

export default data;
import { combineReducers } from 'redux';
import auth from './auth';
import members from './members';
import member from './member';
import activity from './activity';
import attendance from './attendance';
import engagement from './engagement';
import performance from './performance';
import general from './general';
import settings from './settings';
import extras from './extras';
import fitnessCommunities from './fitnessCommunities';
import planning from './planning';
import bodyComp from './bodyComp';
import mindBodyOnline from './mindBodyOnline';
import pike13 from './pike13';

const rootReducer = combineReducers({
  auth,
  members,
  member,
  activity,
  attendance,
  engagement,
  performance,
  settings,
  general,
  extras,
  fitnessCommunities,
  planning,
  bodyComp,
  mindBodyOnline,
  pike13
});

export default rootReducer;
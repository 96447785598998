import {
	GET_PIKE13_ACTIVITY,
	GET_PIKE13_SETTINGS,
	SET_PIKE13_SETTINGS,
	GET_PIKE13_AUTH_LINK,
	SET_PIKE13_MEMBER,
	GET_PIKE13_PROGRAMS,
	LINK_PIKE13_PROGRAMS,
	SYNC_PIKE13_MEMBERS,
	LINK_PIKE13_MEMBERS,
	UPDATE_PIKE13_PROGRAM,
	GET_PIKE13_MANAGE,
	PIKE13_MEMBER_SYNC_EVENT,
	GET_PIKE13_UNLINKED,
	GET_BTWB_PIKE13_UNLINKED,
	UNLINK_PIKE13_MEMBER,
	SEARCH_PIKE13_UNLINKED,
	GET_PIKE13_DUPLICATES,
	GET_PIKE13_IMPORT_STATE,
	PIKE13_CHECK_SELECTED_MEMBER,
	PIKE13_REFRESH_STATUS,
	PIKE13_EMAIL_INVITE,
	CLAIM_PIKE13_BUSINESS,
	GET_PIKE13_BUSINESS,	
} from '../constants';

const initState = {
	fetching: false,
	syncState: 'on',
	lockState: 'off',
	oauthUrl: '',
	options: {
		autoAdd: true,
		autoRemove: true,
		linked: false
	},
	programs: [],
	authFlow: false,
	pike13Id: '',
	username: '',
	url: '',
	programs: [],
	activity: [],
	membersManage: [],
	unlinkedPike13Members: [],
	unlinkedBtwbMembers: [],
	queryResponse: [],
	emailList: [],
	isImporting: false,
	refreshStatus: '',
	listOfBusinesses: [],
	businessClaimed: false
}

const data = (state = initState, action) => {
	switch(action.type) {
		case GET_PIKE13_ACTIVITY:
			return Object.assign({}, state, {
				fetching: action.fetching,
				activity: action.activity ? action.activity : state.activity
			})
			break;
		case GET_PIKE13_SETTINGS:
			return Object.assign({}, state, {
				fetching: action.fetching,
				username: action.username ? action.username : state.username,
				pike13Id: action.pike13Id ? action.pike13Id : state.pike13Id,
				authFlow: action.authFlow ? action.authFlow : false,
				options: {
					autoAdd: action.autoAdd ? action.autoAdd : state.options.autoAdd,
					autoRemove: action.autoRemove ? action.autoRemove : state.options.autoRemove,
					linked: action.linked ? action.linked : state.options.linked
				},
				programs: action.programs ? action.programs : state.programs,
				syncState: action.syncState ? action.syncState : state.syncState,
				lockState: action.lockState ? action.lockState : state.lockState,
				oauthUrl: action.oauthUrl ? action.oauthUrl : state.oauthUrl
			})
			break;
		case SET_PIKE13_SETTINGS: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				options: {
					autoAdd: 'autoAdd' in action ? action.autoAdd : state.options.autoAdd,
					autoRemove: 'autoRemove' in action ? action.autoRemove : state.options.autoRemove,
					linked: 'linked' in action ? action.linked : state.options.linked
				},
				syncState: action.syncState ? action.syncState : state.syncState,
				lockState: action.lockState ? action.lockState : state.lockState
			});
		case SYNC_PIKE13_MEMBERS: 
			return Object.assign({}, state, {
				fetching: action.fetching
			});
		case GET_PIKE13_AUTH_LINK:
			return Object.assign({}, state, {
				fetching: action.fetching,
				authFlow: action.authFlow ? action.authFlow : false,
				url: action.url ? action.url : state.url
			})
		case SET_PIKE13_MEMBER: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				pike13Id: action.mboId ? action.mboId : ''
			})
		case GET_PIKE13_PROGRAMS: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				programs: action.programs ? action.programs : state.programs
			})
		case LINK_PIKE13_PROGRAMS: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				programs: action.programs ? action.programs : state.programs
			});
		case UPDATE_PIKE13_PROGRAM: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				programs: action.programs ? action.programs : state.programs
			})
		case GET_PIKE13_MANAGE:
			return Object.assign({}, state, {
				fetching: action.fetching,
				membersManage: action.members
			})
		case PIKE13_MEMBER_SYNC_EVENT: 
			return Object.assign({}, state, {
				fetching: action.fetching
			})
		case GET_PIKE13_UNLINKED: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				unlinkedPike13Members: action.unlinkedMboMembers
			})
		case GET_BTWB_PIKE13_UNLINKED:
			return Object.assign({}, state, {
				fetching: action.fetching,
				unlinkedBtwbMembers: action.unlinkedBtwbMembers
			})
		case LINK_PIKE13_MEMBERS:
			return Object.assign({}, state, {
				fetching: action.fetching
			})
		case UNLINK_PIKE13_MEMBER:
			return Object.assign({}, state, {
				fetching: action.fetching
			})
		case SEARCH_PIKE13_UNLINKED: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				queryResponse: action.queryResponse
			})
		case GET_PIKE13_DUPLICATES: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				emailList: action.emailList
			})
		case GET_PIKE13_IMPORT_STATE:
			return Object.assign({}, state, {
				fetching: action.fetching,
				isImporting: action.isImporting ? action.isImporting : state.isImporting
			})
		case PIKE13_CHECK_SELECTED_MEMBER: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				membersManage: Array.isArray(action.members) ? action.members : state.membersManage
			})
		case PIKE13_REFRESH_STATUS:
			return Object.assign({}, state, {
				refreshStatus: action.refreshStatus
			})
		case PIKE13_EMAIL_INVITE: 
			return Object.assign({}, state, {
				fetching: action.fetching
			})
		case GET_PIKE13_BUSINESS: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				listOfBusinesses: action.listOfBusinesses ? action.listOfBusinesses : state.listOfBusinesses
			})
		case CLAIM_PIKE13_BUSINESS: 
			return Object.assign({}, state, {
				fetching: action.fetching,
				businessClaimed: true
			})
		default:
			return state;
	}
}

export default data;
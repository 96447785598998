import { 
  GET_FITNESS_COMMUNITY_STATUS
} from '../constants/constants';


const initState = {
  fitness_community_status: ''
}

const fitnessCommunities = (state = initState, action) => {
  switch(action.type){
   case GET_FITNESS_COMMUNITY_STATUS: 
    return Object.assign({}, state, {
      fitness_community_status: action.fitness_community_status
    })
   default: 
    return state;
  }
  return state;
}

export default fitnessCommunities;